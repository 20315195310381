import { createApp } from 'vue'

// import ElementPlus from 'element-plus'
import InfiniteScroll from 'element-plus/es/components/infinite-scroll/src/index'
import Router from '@/router'
import Pinia from '@/store'
import app from '@/App.vue'
import I18n from '@/plugins/i18n'
import VChart from '@/plugins/echarts'
import '@/plugins/day'
import '@/styles/tailwind.css'
// import { getProjectConfig } from './apis/common'
import { checkAuth0 } from '@/utils/auth0'

const init = async () => {
  const root = createApp(app)
  root.use(Pinia)
  root.use(Router)
  await Router.isReady()
  await checkAuth0()
  // await getProjectConfig()
  root.use(I18n)
  // root.use(ElementPlus)
  root.component('VChart', VChart)
  root.directive('infinite-scroll', InfiniteScroll)
  root.mount('#app')
}

init()
