export enum CacheType {
  LOCALSTORAGE,
  SESSIONSTORAGE,
}

export const setCache = (
  key: string,
  value: any,
  type = CacheType.LOCALSTORAGE,
) => {
  if (type === CacheType.LOCALSTORAGE) {
    window.localStorage.setItem(key, JSON.stringify(value))
  }
  if (type === CacheType.SESSIONSTORAGE) {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  }
}

export const getCache = (key: string, type = CacheType.LOCALSTORAGE) => {
  let value: string | null = null

  if (type === CacheType.LOCALSTORAGE) {
    value = window.localStorage.getItem(key)
  }
  if (type === CacheType.SESSIONSTORAGE) {
    value = window.sessionStorage.getItem(key)
  }

  if (value) {
    return JSON.parse(value)
  }
  return undefined
}

export const deleteCache = (key: string, type = CacheType.LOCALSTORAGE) => {
  if (type === CacheType.LOCALSTORAGE) {
    window.localStorage.removeItem(key)
  }
  if (type === CacheType.SESSIONSTORAGE) {
    window.sessionStorage.removeItem(key)
  }
}

export const clearCache = (type = CacheType.LOCALSTORAGE) => {
  if (type === CacheType.LOCALSTORAGE) {
    window.localStorage.clear()
  }
  if (type === CacheType.SESSIONSTORAGE) {
    window.sessionStorage.clear()
  }
}
