export namespace MetricNS {
  export enum PLATFORM {
    tmall = 'tmall',
  }
  export enum CHANNEL {
    total = 'total',
    onebpSearch = 'onebpSearch',
    onebpDisplay = 'onebpDisplay',
    aiAds = 'aiAds',
  }
  export enum SCENE {
    onebpSearch = 'onebpSearch',
    onebpDisplay = 'onebpDisplay',
    adStrategyShangXin = 'adStrategyShangXin',
    adStrategyProductSpeed = 'adStrategyProductSpeed',
    adStrategyCeKuan = 'adStrategyCeKuan',
    adStrategyDkx = 'adStrategyDkx',
    adStrategyRuHui = 'adStrategyRuHui',
    adStrategyYuRe = 'adStrategyYuRe',
    adStrategyWholeShop = 'adStrategyWholeShop',
    adStrategyFans = 'adStrategyFans',
    adStrategyCrowd = 'adStrategyFans',
    onebpLive = 'onebpLive',
    onebpShortVideo = 'onebpShortVideo',
    onebpUnion = 'onebpUnion',
  }
  export enum MODULE {
    performance = 'performance',
    list = 'list',
  }
  export enum SELECT_TYPE {
    optional = 1,
    required = 2,
    optionalAndDefault = 3,
    hidden = 4,
  }
  export interface MetricConfig {
    metricId: string
    platform: PLATFORM
    channel: CHANNEL
    scene: SCENE
    metricType: string
    standardName: string
    platformMetricName: string
    layer: string
    page: string
    module: MODULE
    selectType: SELECT_TYPE
    displayName: {
      'en-US': string
      'zh-CN': string
    }
    tooltips: {
      'en-US': string
      'zh-CN': string
    }
    sortNo: number
    extendInfo: {
      dataType: string
      displayType: string
      displayDecimalPlaces: number
      unit: string
    }
  }
}
