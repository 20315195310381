import { defineStore } from 'pinia'
import { User } from '@/typings'
import { getCache, setCache } from '@/utils/cache'
import { TOKEN } from '@/configs/common'
import { USER_PARTNER_TYPE, USER_STATUS } from '@/constants/common'

export const useAuth0Store = defineStore(User.name, {
  state: (): User.State => ({
    idToken: getCache(TOKEN),
    accountInfo: {
      email: '',
      name: '',
      phone: '',
      source: '',
      userId: '',
      status: USER_STATUS.PENDING,
      partnerType: USER_PARTNER_TYPE.INTERNAL,
    },
  }),
  actions: {
    setToken(token: string) {
      this.idToken = token
      setCache(TOKEN, token)
    },
    clearToken() {
      this.idToken = ''
    },
    getToken() {
      return getCache(TOKEN)
    },
    resetAccountInfo() {
      this.accountInfo = {
        email: '',
        name: '',
        phone: '',
        source: '',
        userId: '',
        status: USER_STATUS.PENDING,
        partnerType: USER_PARTNER_TYPE.INTERNAL,
      }
    },
    setAccountInfo(data: any) {
      this.accountInfo = data
    },
    isUserInvalid() {
      return [USER_STATUS.INACTIVE, USER_STATUS.PENDING].includes(
        this.accountInfo.status,
      )
    },
  },
  getters: {
    checkUserInvalid: state =>
      [USER_STATUS.INACTIVE, USER_STATUS.PENDING].includes(
        state.accountInfo.status,
      ),
    isSaasUser: state =>
      state.accountInfo.partnerType === USER_PARTNER_TYPE.SAAS,
    isInternalUser: state =>
      state.accountInfo.partnerType === USER_PARTNER_TYPE.INTERNAL,
  },
})
