import NProgress from 'nprogress'
import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import 'nprogress/nprogress.css'
import ExceptionRoutes from './routes/exception'
import dashboardRoutes from './routes/dashboard'
import authenticationRoutes from './routes/authentication'

NProgress.configure({ showSpinner: false })

const routes: Array<RouteRecordRaw> = [
  ...dashboardRoutes,
  ...ExceptionRoutes,
  ...authenticationRoutes,
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (_, __, next) => {
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
