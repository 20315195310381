import { createI18n } from 'vue-i18n'
import messages from '@/locales'
import { CommonNS } from '@/typings'

interface Locale {
  [key: string]: Record<string, any>
}

function getLocale(lang: CommonNS.Language, lcs: Locale): Locale {
  let locale: Locale = {}

  if (Object.prototype.hasOwnProperty.call(lcs, lang)) {
    locale = lcs[lang]
  } else {
    Object.keys(lcs).forEach((key: string) => {
      locale[key] = getLocale(lang, lcs[key])
    })
  }

  return locale
}

const userLanguage = navigator.language.match(/^en/i)
  ? CommonNS.Language.EN
  : CommonNS.Language.ZH

const i18n = createI18n({
  warnHtmlInMessage: 'off',
  warnHtmlMessage: false,
  legacy: false,
  locale: userLanguage,
  fallbackLocale: CommonNS.Language.ZH,
  messages: {
    [CommonNS.Language.EN]: getLocale(CommonNS.Language.EN, messages),
    [CommonNS.Language.ZH]: getLocale(CommonNS.Language.ZH, messages),
  },
})

export default i18n
