export const enum API {
  // config
  PROJECT_CONFIG = '/project/config',
  // activity track
  ACTIVITY_TRACK = '/project/activity/track',
  // campaign
  METRIC_LIST = '/metric/list',
  PERFORMANCE_OVERVIEW_CARDS = '/report/performance_overview/cards',
  PERFORMANCE_OVERVIEW_TREND = '/report/performance_overview/trend',
  CAMPAIGN_LIST = '/campaign/campaign_list',
  APAC_CAMPAIGN_LIST = '/campaign/apac_campaign_list',
  APAC_CAMPAIGN_BUDGET = '/campaign/apac_campaign_budget',
  APAC_CAMPAIGN_EXPORT_TEMPLATE = '/campaign/apac_campaign_export_template',
  APAC_CAMPAIGN_IMPORT = '/campaign/apac_campaign_import',
  APAC_CAMPAIGN_NAME_LIST = '/campaign/apac_campaign_name_list',
  APAC_CAMPAIGN_STATUS_TARGET_LIMIT = '/campaign/apac_campaign_status_target_limit',
  APAC_CAMPAIGN_TARGET_LIMIT = '/campaign/apac_campaign_target_limit',
  APAC_CAMPAIGN_PERFORMANCE_OVERVIEW = '/campaign/apac_campaign_performance_overview',
  APAC_CAMPAIGN_PERFORMANCE_CHART = '/campaign/apac_campaign_performance_chart',
  // strategy
  ASSIGNED_STRATEGY_LIST = '/automation_manager/strategies',
  GET_STORE_LIST = '/automation_manager/store',
  GET_RULE_LIST = '/automation_manager/rules',
  CREATE_STRATEGY = '/automation_manager/strategy/create',
  EDIT_STRATEGY = '/automation_manager/strategy/update',
  VALIDATE_STRATEGY = '/automation_manager/strategy/validate',
  UPDATE_VALIDATE_STRATEGY = '/automation_manager/strategy/validate/update',
  GET_STRATEGY_DETAIL = '/automation_manager/strategy',
  GET_STRATEGY_EVENT = '/automation_manager/strategy/events',
  GET_CAMPAIGN_SKU = '/automation_manager/campaign_sku_list',
  UPDATE_STRATEGY_STATUS = '/automation_manager/strategy/execution',
  GET_SPECIFIC_LIST = '/automation_manager/strategy/campaign_sku_metric_list',
  GET_SPECIFIC_KEYWORD_LIST = '/automation_manager/keyword_metric_list',
  GET_FILTER_STRATEGY = '/automation_manager/strategy/filter_assigned_list',
  RETRY_STRATEGY_FAILED_ACTION = '/automation_manager/strategy/event/retry',
  GET_FILTER_KEYWORD_LIST = '/automation_manager/keyword_event_list',
  DISMISS_ERROR = '/automation_manager/dismiss_error_strategy',
  ADS_LIST = '/automation_manager/ads_list',
  DISPLAY_WARNING_STRATEGY = '/automation_manager/display_warning_strategy',
  CHECK_ACTIVE_STRATEGY = '/automation_manager/strategy/validate/enable',
  GET_STRATEGY_TEMPLATE = '/strategy_template/templates',
  GET_EXECUTION_LOG = '/automation_manager/strategy/execution_log',
  GET_EXECUTION_DETAIL_INFO = '/automation_manager/strategy/execution_detail_info',
  GET_EXECUTION_EVENTS = '/automation_manager/strategy/execution_events',
  // trade desk
  TD_METRIC_CARD = '/trade_desk/card',
  ALERT_LIST = '/alert/list',
  UPDATE_ALERT_STATUS = '/alert/update/status',
  ALERT_ADD = '/alert/add',
  ALERT_CHECK_DUPLICATE = '/alert/check/duplicate',
  // user
  TMALL_STORES = '/user/stores',
  USER_STORES = '/user/stores',
  STORES_SORT = '/user/stores/sort',
  CHECK_TOKEN = '/user',
  CHECK_PROFILE = '/user/profile',
}
