import VChart from 'vue-echarts'

import { use } from 'echarts/core'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import {
  BarChart,
  CustomChart,
  EffectScatterChart,
  GraphChart,
  LineChart,
  PieChart,
  ScatterChart,
} from 'echarts/charts'
import {
  DataZoomComponent,
  DataZoomSliderComponent,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  LegendScrollComponent,
  MarkAreaComponent,
  MarkLineComponent,
  MarkPointComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
} from 'echarts/components'

use([
  SVGRenderer,
  CanvasRenderer,
  BarChart,
  PieChart,
  LineChart,
  ScatterChart,
  GraphChart,
  CustomChart,
  GridComponent,
  TitleComponent,
  DatasetComponent,
  LegendComponent,
  LegendScrollComponent,
  TooltipComponent,
  ToolboxComponent,
  DataZoomComponent,
  VisualMapComponent,
  MarkPointComponent,
  DataZoomSliderComponent,
  EffectScatterChart,
  MarkLineComponent,
  MarkAreaComponent,
])

export default VChart
