import {
  castArray,
  divide,
  find,
  first,
  flow,
  forEach,
  fromPairs,
  get,
  groupBy,
  includes,
  isEmpty,
  isEqual,
  isFinite,
  isFunction,
  isNil,
  isPlainObject,
  isString,
  last,
  map,
  mapValues,
  mergeWith,
  round,
  sampleSize,
  size,
  slice,
  sortBy,
  split,
  template,
  toNumber,
  toString,
  trim,
} from 'lodash-es'
import $day from 'dayjs'
import { OPERATORS, PLATFORM } from '@/constants/common'
import type { DataTableNS, StrategyNS } from '@/typings'
import { STRATEGY_RULE_SCHEDULE } from '@/constants/strategy'
import { COUNTRY_CURRENCY } from '@/configs/strategy'
import { CAMPAIGN_COLUMNS, METRIC_CURRENCY } from '@/constants/campaign'

enum Letters {
  'mixed',
  'lower',
  'upper',
  'alpha',
  'number',
}
export type LettersType = keyof typeof Letters

/**
 * JSON 深拷贝
 * @param target 拷贝目标
 */
export function cloneJSON<T>(target: T): T {
  return JSON.parse(JSON.stringify(target))
}

/**
 * JSON 值相等判断
 * @param target1 比较目标一
 * @param target2 比较目标二
 */
export function isEqualJSON<T, K>(target1: T, target2: K): boolean {
  return JSON.stringify(target1) === JSON.stringify(target2)
}

/**
 * 转换目标为数组
 * @param target 转换目标
 */
export function convertToArray<T>(target: T) {
  if (!target) return []
  return Array.isArray(target) ? target : [target]
}

/**
 * 补充 Animate.css Class 前缀
 * @param animates animations with https://animate.style/
 */
export function animateUtil(...animates: string[]) {
  return ['animate__animated', animates.map(item => `animate__${item}`)]
}

/**
 * generate random letters
 * @param type - letters type
 * @param len - letters length
 */
export function getRandomLetters(type: LettersType = 'mixed', len = 8): string {
  const numbers = '0123456789'
  const lowerLetters = 'abcdefghijklmnopqrstuvwxyz'
  const upperLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

  let charset = ''

  switch (type) {
    case 'number':
      charset = numbers
      break
    case 'lower':
      charset = lowerLetters
      break
    case 'upper':
      charset = upperLetters
      break
    case 'alpha':
      charset = `${upperLetters}${lowerLetters}`
      break
    case 'mixed':
      charset = `${upperLetters}${lowerLetters}${numbers}`
      break
    default:
      break
  }

  return sampleSize(charset, len).join('')
}

export const parseQueryParams = (queryString: string) => {
  const searchParams = new URLSearchParams(queryString)
  const queryParams: { [key: string]: string } = {}

  for (const [key, value] of searchParams.entries()) {
    queryParams[key] = value
  }

  return queryParams
}

export const isBlank = (value: any) => isNil(value) || isEmpty(toString(value))

export const parseStringToBoolean = (value: string) => /^true$/i.test(value)

export const filterObject = (collection: any, predicate: any) =>
  flow([
    Object.entries,
    (arr: [any, any][]) => arr.filter(([key, val]) => predicate(val, key)),
    Object.fromEntries,
  ])(collection)

export const diffAssoc = (target: any, values: any) => {
  const collection: any = {}
  Object.keys(target).forEach(key => {
    if (
      !isEqual(values[key], target[key]) ||
      size(values[key]) !== size(target[key])
    ) {
      collection[key] = target[key]
    }
  })
  return collection
}

export const escapeRegexpString = (value = '') =>
  String(value).replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')

export const generateClassFromObject = (obj: any) => {
  const convertObjectToString = (arrs: any) =>
    Object.keys(filterObject(arrs, (val: any) => val)).join(' ')

  if (Array.isArray(obj)) {
    return map(obj, item =>
      isString(item) ? item : convertObjectToString(item),
    ).join(' ')
  }
  return convertObjectToString(obj)
}

export const splitQueryParam = (key: string) => {
  const { query } = useRoute()
  return query[key] ? split(query[key]?.toString(), ',') : []
}

export const checkOverlap = (data: any[]) => {
  const overlaps: any[] = []
  const isOverlap = (intervalI: any, intervalJ: any) => {
    const [startI, endI] = Array.isArray(intervalI)
      ? intervalI
      : [intervalI, intervalI]
    const [startJ, endJ] = Array.isArray(intervalJ)
      ? intervalJ
      : [intervalJ, intervalJ]

    const overlap =
      (startI <= startJ && startJ <= endI) ||
      (startI <= endJ && endJ <= endI) ||
      (startJ <= startI && startI <= endJ) ||
      (startJ <= endI && endI <= endJ)
    return overlap
  }

  forEach(data, (itemI, i) => {
    forEach(slice(data, i + 1), itemJ => {
      if (isOverlap(itemI, itemJ)) {
        overlaps.push([itemI, itemJ])
      }
    })
  })

  return overlaps.length > 0
}

export const findDivisors = (n: number): number[] =>
  Array.from({ length: n }, (_, index) => index + 1).filter(i => n % i === 0)

export const evalFunc = (conditionCheck: string, params: any) => {
  const compiled = template(`<%= ${trim(conditionCheck)} %>`)
  return parseStringToBoolean(compiled(params))
}

export const detachNumberFromString = (condition: string) => {
  const regex = /(\d+(\.\d+)?)/g
  return condition.match(regex)
}

export const isNotEmpty = (val: any) => !isEmpty(val)

export const compiledTemplate = (strTemp: string, params?: any) => {
  const compiled = template(strTemp, { interpolate: /{{([\s\S]+?)}}/g })
  return compiled(params)
}

export const checkDecimalDigits = (num: number, point = 2) => {
  const splitNumber = split(toString(num), '.', 2)
  const digits = get(splitNumber, 1)
  return size(digits) <= point
}

const setValueByType = (
  value: any,
  type: any,
  metric: string,
  operator: any,
) => {
  switch (type) {
    case 'decimal':
    case 'integer':
      return Array.isArray(value) ? map(value, toNumber) : Number(value)
    case 'dropdown': {
      if (metric === STRATEGY_RULE_SCHEDULE.STORE_LOCAL_TIME) {
        const localValue = convertStoreLocalTimeValue(castArray(value))
        if (!includes([OPERATORS.IN, OPERATORS.NOT_IN], operator)) {
          return first(localValue) ?? undefined
        }
        return localValue
      }
      return Array.isArray(value) ? value : String(value)
    }
    case 'string': {
      if (Array.isArray(value)) {
        if ([OPERATORS.CONTAINS, OPERATORS.EXCLUDES].includes(operator)) {
          return Array.from(value).map(v =>
            isString(v) ? { key: v, label: v, name: v } : v,
          )
        }
        return value
      }
      return String(value)
    }
    case 'datetime':
      return Array.isArray(value)
        ? map(value, v => $day(v).format())
        : $day(value).format()
    default:
      return value
  }
}

export const setInputValueByType = (
  value: any,
  type: any,
  metric: string,
  operator: any,
) => {
  if (isPlainObject(value)) {
    return [
      setValueByType(value.start, type, metric, operator),
      setValueByType(value.end, type, metric, operator),
    ]
  }
  return setValueByType(value, type, metric, operator)
}

export const convertMapOptions = (
  data: any,
  trans?: any,
  mapIcons?: Record<string, any>,
): DataTableNS.DataColumnStatus[] =>
  map(data, (val, key) => ({
    value: key.toLowerCase(),
    label: isFunction(trans) ? trans(val) : val,
    icon: get(mapIcons, key),
  }))

export const checkNoLimitValue = (value: number, platform: string) =>
  (value === 0 && platform === PLATFORM.SHOPEE) ||
  (value === -1 && platform === PLATFORM.LAZADA)

export const mergeParams = (target: any, source: any) =>
  mergeWith(target, source, (oldValue: any, srcValue: any) => {
    if (Array.isArray(oldValue)) {
      return srcValue
    }
    return srcValue
  })

export const mergeRouteQueryParams = (params: any) =>
  mapValues(params, item => (Array.isArray(item) ? item.join(',') : item))

export const indexOfSmallest = (array: Array<any>) => {
  let lowest = 0
  for (let i = 1; i < array.length; i += 1) {
    if (array[i] < array[lowest]) lowest = i
  }
  return lowest
}

export const indexOfSmallestAboveThreshold = (
  array: Array<number>,
  threshold: number,
): number => {
  let minIndex = -1
  let minValue = Infinity

  for (let i = 0; i < array.length; i += 1) {
    if (array[i] > threshold && array[i] < minValue) {
      minValue = array[i]
      minIndex = i
    }
  }
  return minIndex
}

export const timeStringToMillisecond = (time: string) => {
  const hoursMinutes = time.split(/[.:]/)
  const hours = parseInt(hoursMinutes[0], 10)
  const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0
  return hours * 3600000 + minutes * 60000
}

export const parseRouteQueryParams = (fields: string[]) =>
  fromPairs(Array.from(fields, (item: string) => [item, splitQueryParam(item)]))

export const castArrayNotEmpty = (val: any) =>
  castArray(val).filter(item => !isBlank(item))

export const ifNull = (item: any, defaultValue: any) =>
  isNil(item) ? defaultValue : item

const convertStoreLocalTimeValue = (data: any): StrategyNS.StoreLocalTime[] => {
  if (isNil(data[0]) || isPlainObject(data[0])) return data
  return convertStringArrayToStoreLocalTime(data)
}

export const convertStoreLocalTimeToStringArray = (
  data: StrategyNS.StoreLocalTime[],
) => data.map(item => item.time.map(t => `${item.dayOfWeek}_${t}`)).flat()

export const convertStringArrayToStoreLocalTime = (
  data: string[],
): StrategyNS.StoreLocalTime[] =>
  map(
    groupBy(data, item => first(item.split('_'))),
    (val: any[], key: any) =>
      ({
        dayOfWeek: Number(key),
        time: map(val, v => last(v.split('_'))),
      }) as StrategyNS.StoreLocalTime,
  )

export const capitalize = (str: string) => {
  str = str.toLowerCase()
  return (str && str[0].toUpperCase() + str.slice(1)) || ''
}

export const divideNumber = (
  val: number,
  divisor: number,
  precision?: number,
) => {
  const newValue = divisor > 0 ? divide(val, divisor) : 0
  if (precision) return newValue
  return round(newValue, precision)
}

export const logBase = (number: number, base: number) => {
  const newValue = divideNumber(Math.log(number), Math.log(base), 0)
  return isFinite(newValue) ? newValue : 0
}

export const reverseRecord = <T extends PropertyKey, U extends PropertyKey>(
  input: Record<T, U>,
) =>
  Object.fromEntries(
    Object.entries(input).map(([key, value]) => [value, key]),
  ) as Record<U, T>

export const getEnumValue = (key: string, enumType: any) => {
  if (key in enumType) {
    return enumType[key as keyof typeof enumType]
  }
  return undefined
}
export const generateColumnsFromMetrics = (
  metrics: string[],
  countryCode: string,
  platform: string,
  trans: any,
) => {
  const lstColumns = map(metrics, col => {
    const metricOpts =
      find(CAMPAIGN_COLUMNS[platform].metrics, ['key', col]) ?? {}
    const labelKey = METRIC_CURRENCY.includes(col)
      ? '{{name}} ({{currency}})'
      : '{{name}}'
    return {
      key: col,
      label: compiledTemplate(labelKey, {
        name: trans(`metrics.${col}`),
        currency: COUNTRY_CURRENCY[countryCode],
      }),
      order: metricOpts?.order ?? -1,
    }
  })
  return sortBy(lstColumns, 'order')
}
export const getDisplayCurrency = (countryCode: string) =>
  COUNTRY_CURRENCY[countryCode]
