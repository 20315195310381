import $day from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import isoWeek from 'dayjs/plugin/isoWeek'

$day.extend(utc)
$day.extend(timezone)
$day.extend(relativeTime)
$day.extend(isoWeek)
