/**
 * 应用通用错误码
 * https://yimiandata.feishu.cn/docs/doccnB89DyruY2uD6UeQsbpuddb#DsHSTH
 */

export const ERROR_CODES: Record<string, string> = {
  A0000: 'CLIENT_ERROR',
  A0001: 'UNAUTHORIZED',
  A0002: 'UNAUTHORIZED_APP',
  A0003: 'PERMISSION_DENIED',
  A0004: 'NOT_FOUND',
  A0005: 'INVALID_CLIENT_APP_KEY',
  A0006: 'INVALID_APP_KEY',
  A0007: 'INVALID_TOKEN',
  A0008: 'ALREADY_EXISTS',
  A0009: 'JSON_FORMAT_ERROR',
  A0010: 'INVALID_ARGUMENT',
  A0011: 'FILE_EXCEED_MAX_LEN',
  A0012: 'INVALID_FILE_FORMAT',
  A0101: 'INVALID_USERNAME',
  A0102: 'EMAIL_EXISTS',
  A0103: 'EMAIL_DOES_NOT_EXIST',
  A0104: 'EMAIL_NOT_CONFIRMED',
  A0105: 'ACCOUNT_IS_LOCKED',
  A0106: 'ACCOUNT_IS_DISABLED',
  A0107: 'ACCOUNT_IS_NOT_ACTIVATED',
  A0108: 'INVALID_LOGIN_OR_PASSWORD',
  A0109: 'INCORRECT_CURRENT_PASSWORD',
  A0110: 'UNABLE_TO_LOGIN_BY_PASSWORD',
  A0111: 'EMAIL_IS_REQUIRED',
  A0112: 'INVALID_EMAIL_ADDRESS',
  A0601: 'USER_NOT_EXISTS',
  A0602: 'USERNAME_EXISTS',
  A0603: 'NOT_ALLOWED_CREATE_USER',
  A0604: 'NOT_ALLOWED_REGISTER',
  A0605: 'NOT_BIND_CURRENT_APP',
  A0606: 'ALREDDY_BIND_CURRENT_APP',
  A0607: 'ACCOUNT_EXPIRED',
  A0608: 'USERNAME_TOO_LONG',
  A0609: 'PASSWORD_NOT_MATCH_CONFIRM_PASSWORD',
  A5000: 'RESOURCE_EXISTED',
  A5001: 'RESOURCE_NOT_EXIST',
  A5002: 'APP_CALL_LIMITED',
  A5003: 'VERIFICATION_CODE_ERROR',
  A5004: 'VERIFICATION_CODE_EXPIRE',
  A5005: 'STRATEGY_PERMISSION_DENIED',
  A5010: 'CAPTCHA_SEND_RATE_LIMIT',
  A5100: 'APPLICATION_EXISTED',
  A5101: 'APPLICATION_NOT_EXIST',
  A5102: 'APPLICATION_DISABLED',
  A5200: 'ROLE_EXISTED',
  A5201: 'ROLE_NOT_EXIST',
  A5300: 'SUBJECT_EXISTED',
  A5301: 'SUBJECT_NOT_EXIST',
  A5302: 'SUBJECT_NO_DELETE',
  A5400: 'PERMISSION_GROUP_EXISTED',
  A5401: 'PERMISSION_GROUP_NOT_EXIST',
  A5500: 'POLICY_EXISTED',
  A6001: 'DUPLICATED CONDITION',
  B0000: 'SERVER_ERROR',
  B0001: 'INTERNAL_SERVER_ERROR',
  B0002: 'SERVICE_UNAVAILABLE',
  B0003: 'REMOTE_SERVICE_ERROR',
  B0004: 'IP_LIMIT',
  B0005: 'REQUEST_API_NOT_FOUND',
  B0200: 'DATABASE_ERROR',
  B0201: 'QUERY_TIMEOUT',
  B0202: 'OUT_OF_MEMORY',
  B0203: 'COLUMN_DOESNT_EXIST',
  B0204: 'MANY_CONNECTION_ERRORS',
  B0205: 'USER_PASSWORD_NOT_MATCH',
  B0206: 'SQL_SYNTAX_ERROR',
  B0207: 'QUERY_FAILED',
  B0208: 'SQL_EXECUTE_FAILED',
  B0209: 'CACHE_ERROR',
  B5000: 'CASBIN_ERROR',
  C0000: 'THIRD_PARTY_ERROR',
  C0101: 'INVALID_LOGIN_METHOD',
  C0102: 'NEED_TO_LOG_IN_WITH_WECHAT',
  C0701: 'NEED_TO_BIND_WECHAT',
  C0702: 'CODE_AND_TOKEN_NOT_MATCH',
  C0703: 'ACCOUNT_HAS_BEEN_BOUND',
  D0000: 'UNKNOWN_ERROR',

  // auth0
  auth0_err_login: 'accounts.phrase.account_or_password_incorrect',
  auth0_err_lock: 'accounts.phrase.account_frozen_message',
}

export const ERROR_STATUS: Record<number, string> = {
  400: 'BAD_REQUEST',
  401: 'UNAUTHORIZED',
  403: 'FORBIDDEN',
  404: 'NOT_FOUND',
  500: 'INTERNAL_SERVER_ERROR',
  501: 'NOT_IMPLEMENTED',
  502: 'BAD_GATEWAY',
  503: 'SERVICE_UNAVAILABLE',
  504: 'GATEWAY_TIMEOUT',
}
