import { defineStore } from 'pinia'

import { Controller } from '@/typings'

export const useControllerStore = defineStore(Controller.name, {
  state: (): Controller.State => ({
    controllers: [],
  }),
  actions: {
    cancelControllers() {
      this.controllers = this.controllers.reduce((result, controller) => {
        if (controller) controller.abort()
        return result
      }, [])
    },
    addController(controller: AbortController | AbortController[]) {
      this.controllers.push(
        ...(Array.isArray(controller) ? controller : [controller]),
      )
    },
  },
})
