import type { MetricNS } from './metric'

export namespace TradeDeskNS {
  export enum SUB_PAGE {
    OVERVIEW = 'overview',
    CONFIG = 'config',
  }
  export enum Dimension {
    STORE = 'store',
    CAMPAIGN = 'campaign',
    SKU = 'sku',
    KEYWORD = 'keyword',
  }
  export enum DurationType {
    REAL_TIME = 1,
    YESTERDAY = 2,
    CURRENT_MONTH = 3,
    CUSTOMER_LAST_DAYS = 4,
    CUSTOMER_NEXT_DAYS = 5,
  }
  export enum Frequency {
    DAILY = 1,
    WORKDAY = 2,
    WEEKLY = 3,
    MONTHLY = 4,
  }
  export enum Operator {
    '>=' = '>=',
    '>' = '>',
    '<=' = '<=',
    '<' = '<',
    '=' = '=',
    '!=' = '!=',
  }
  export enum AlertType {
    INSUFFICIENT_BALANCE = 1,
    ROI_NOT_MET = 2,
  }
  export enum AlertStatus {
    CREATE = 'create',
    EDIT = 'edit',
    COPY = 'copy',
  }
  export enum Condition {
    AND = 'AND',
    OR = 'OR',
  }
  export interface tdCardReq {
    cur_dt: string
    cmp_dt: string
    metrics: string[]
    channel: MetricNS.CHANNEL
    platform: MetricNS.PLATFORM
    scene: MetricNS.SCENE
    session_key: string
  }
  export interface TrendData {
    dt: string
    metric: string
    value: number
  }
  export interface tdMetricCard {
    metric: string
    value: number
    pastValue: number
    cmp: number
    channelKeys: string[]
    channelValues: number[]
    curDates: TrendData[]
    cmpDates: TrendData[]
  }
  export interface Alert {
    name: string
    alertType: AlertType | 0
    storeId: string
    platform: string
    channel: MetricNS.CHANNEL | ''
    scene: MetricNS.SCENE | ''
    applyLayer: Dimension | ''
    topPercent: number
    frequency: Frequency | 0
    scheduleInfo: any
    cronExpressions: string[]
  }
  export type BasicAlert = Pick<
    Alert,
    'name' | 'alertType' | 'storeId' | 'platform' | 'channel' | 'scene'
  >
  export interface ScheduleInfo {
    frequency: Frequency
    groups: Array<{
      value: string
      times: string[]
    }>
  }

  export interface AlertListReq {
    limit: number
    offset: number
  }

  export interface AlertListItem {
    alertType: 1 | 0
    applyLayer: string
    channel: string
    conditionGroup: any[]
    createdAt: string
    creator: string
    creatorEmail: string
    creatorName: string
    cronExpressions: any[]
    frequency: number
    id: string
    modifier: string
    modifierEmail: string
    modifierName: string
    name: string
    platform: string
    scene: string
    scheduleInfo: {
      displayTime: string
      displayType: string
    }
    status: number
    storeId: string
    topPercent: number
    updatedAt: string
  }

  export interface UpdateStatusReq {
    id: string
    status: number
  }
}
