/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import { isEmpty } from 'lodash-es'

import { MethodEnum } from '@/typings/api'
import type { Http, HttpFunction } from '@/typings/api'

import axios from './axios'

function parseQuery(api: string, query: { [key: string]: string } = {}) {
  if (isEmpty(query)) return api

  return Object.entries(query).reduce((result: string, [key, value]) => {
    result = result.replace(key, value)
    return result
  }, api)
}

const httpFunction: HttpFunction = async context => {
  const { api, query, doneHook, data: requestData, ...props } = context
  let data = null

  try {
    data = await axios({
      url: parseQuery(api as unknown as string, query),
      data: requestData,
      ...props,
    })
    doneHook && doneHook(false)
    return Promise.resolve(data)
  } catch (error: any) {
    if (error?.message === 'CANCELED') {
      doneHook && doneHook(false)
      return Promise.resolve(data)
    }
    return Promise.reject(error)
  }
}

export const http = Object.values(MethodEnum).reduce((result, method) => {
  result[method] = async config => {
    const res = await result({
      ...config,
      method,
    })
    return res
  }
  return result
}, httpFunction as Http)

export * from './path'
