// #region - camp filter date shortcuts
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isoWeek from 'dayjs/plugin/isoWeek'
import { format2Timestamp } from '@/utils/day'
import { DateNS } from '@/typings'

dayjs.extend(utc)
dayjs.extend(isoWeek)

export interface Shortcut {
  key: DateNS.DYNAMIC_DATE
  value: (string | dayjs.Dayjs)[]
  tipping?: string
}
const T = dayjs()
  .utc()
  .utcOffset(7 * 60)
export const dateConfig = {
  T,
  'T-90': T.subtract(90, 'day'),
  'T-99': T.subtract(99, 'day'),
  'T-1': T.subtract(1, 'day'),
  'T-199': T.subtract(199, 'day'),
  maxDurationInDay: 90,
}
export const shortcuts: Shortcut[][] = [
  [
    {
      key: DateNS.DYNAMIC_DATE.today,
      value: Array(2).fill(dateConfig.T),
    },
    {
      key: DateNS.DYNAMIC_DATE.yesterday,
      value: Array(2).fill(dateConfig['T-1']),
    },
    {
      key: DateNS.DYNAMIC_DATE.currentWeek,
      value: [
        dayjs(dateConfig.T).startOf('isoWeek'),
        dayjs(dateConfig.T)
          .subtract(1, 'day')
          .isBefore(dayjs(dateConfig.T).startOf('isoWeek'))
          ? dayjs(dateConfig.T).startOf('isoWeek')
          : dayjs(dateConfig.T).subtract(1, 'day'),
      ],
      tipping: 'Mon - Yesterday',
    },
    {
      key: DateNS.DYNAMIC_DATE.lastWeek,
      value: [
        dayjs(dateConfig.T).subtract(7, 'day').startOf('isoWeek'),
        dayjs(dateConfig.T).subtract(7, 'day').endOf('isoWeek'),
      ],
      tipping: 'Mon - Sun',
    },
    {
      key: DateNS.DYNAMIC_DATE.currentMonth,
      value: [
        dayjs(dateConfig.T).startOf('month'),
        dayjs(dateConfig.T).subtract(1, 'day'),
      ],
    },
    {
      key: DateNS.DYNAMIC_DATE.lastMonth,
      value: [
        dayjs(dateConfig.T).subtract(1, 'month').startOf('month'),
        dayjs(dateConfig.T).subtract(1, 'month').endOf('month'),
      ],
    },
    {
      key: DateNS.DYNAMIC_DATE.last7Days,
      value: [
        dayjs(dateConfig.T).subtract(7, 'day'),
        dayjs(dateConfig.T).subtract(1, 'day'),
      ],
    },
    {
      key: DateNS.DYNAMIC_DATE.last30Days,
      value: [
        dayjs(dateConfig.T).subtract(30, 'day'),
        dayjs(dateConfig.T).subtract(1, 'day'),
      ],
    },
    {
      key: DateNS.DYNAMIC_DATE.last90Days,
      value: [
        dayjs(dateConfig.T).subtract(90, 'day'),
        dayjs(dateConfig.T).subtract(1, 'day'),
      ],
    },
  ],
]
export function generateTimeStampByMode(mode: DateNS.DYNAMIC_DATE) {
  return unref(shortcuts)
    .flat()
    .find(item => item.key === mode)!
    .value.map(str => format2Timestamp(str)) as [number, number]
}
// #endregion

export const PERIOD_MAP: Record<string, string> = {
  [DateNS.DYNAMIC_DATE.today]: 'today',
  [DateNS.DYNAMIC_DATE.yesterday]: 'yesterday',
  [DateNS.DYNAMIC_DATE.currentWeek]: 'this_week',
  [DateNS.DYNAMIC_DATE.lastWeek]: 'last_week',
  [DateNS.DYNAMIC_DATE.currentMonth]: 'this_month',
  [DateNS.DYNAMIC_DATE.lastMonth]: 'last_month',
  [DateNS.DYNAMIC_DATE.last7Days]: 'l7d',
  [DateNS.DYNAMIC_DATE.last15Days]: 'l15d',
  [DateNS.DYNAMIC_DATE.last30Days]: 'l30d',
  [DateNS.DYNAMIC_DATE.last90Days]: 'l90d',
  [DateNS.DYNAMIC_DATE.custom]: 'custom',
}
