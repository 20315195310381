import auth0 from 'auth0-js'
import { deleteCache } from './cache'
import { checkToken, checkUserProfile } from '@/apis/user'
import { activityTrackUserLogin } from '@/apis/common'
import { TOKEN } from '@/configs/common'
import { useAuth0Store, useCommonStore } from '@/store'
import { CacheType, getCache, setCache } from '@/utils/cache'
import router from '@/router'

let webAuth: auth0.WebAuth
const {
  VITE_AUTH0_DOMAIN: domain,
  VITE_AUTH0_CLIENT_ID: clientID,
  VITE_AUTH0_REDIRECT_URI: redirectUri,
} = import.meta.env

export function initAuth0() {
  webAuth = new auth0.WebAuth({
    domain,
    clientID,
    responseType: 'token',
    scope: 'openid profile email',
  })
}

export function authorize(state: string) {
  if (!webAuth) {
    initAuth0()
  }
  webAuth.authorize({
    redirectUri,
    connection: 'Username-Password-Authentication',
    state,
  })
}

export async function checkAuth0(): Promise<void> {
  const commonStore = useCommonStore()
  const auth0Store = useAuth0Store()
  const tokenCache = getCache(TOKEN)
  const state = Date.now().toString()

  if (tokenCache) {
    await checkToken(tokenCache)
      .then(async (data: any) => {
        if (data.isValid) {
          auth0Store.setAccountInfo(data?.accountUserInfo)
          // NOTE: set sessionStorage email in case get user profile API return nothing
          window.sessionStorage.setItem(
            'current-user-email',
            data?.accountUserInfo?.email,
          )
          await trackUserActivity()
        } else {
          // setCache(state, window.location.href, CacheType.SESSIONSTORAGE)
          // authorize(state)
          const userProfile = data?.accountUserInfo
          if (userProfile) {
            await trackUserActivity()
            if (
              userProfile.status === 'inactive' ||
              userProfile.status === 'pending'
            ) {
              window.sessionStorage.setItem(
                'unauthorized-email',
                userProfile.email,
              )
              window.sessionStorage.setItem(
                'unauthorized-status',
                userProfile.status,
              )
              window.sessionStorage.setItem(
                'unauthorized-approval-url',
                `${import.meta.env.VITE_POWERHUB_URL}/user/authorize?token=${
                  userProfile.approvalToken
                }`,
              )
              router.push({ path: '/unauthorized' })
            } else {
              await logout()
            }
          } else {
            await logout()
          }
        }
        commonStore.renderAllowed = data.isValid
      })
      .catch(async err => {
        console.log(err)
        // NOTE: consider to using logout
        // await logout()
        commonStore.renderAllowed = false
      })
  } else {
    setCache(state, window.location.href, CacheType.SESSIONSTORAGE)
    authorize(state)
  }
}

export async function checkUserStatus(token: string): Promise<void> {
  await checkUserProfile(token)
    .then((userProfile: any) => {
      if (
        userProfile.status === 'pending' ||
        userProfile.status === 'inactive'
      ) {
        window.sessionStorage.setItem('unauthorized-email', userProfile.email)
        window.sessionStorage.setItem('unauthorized-status', userProfile.status)
        window.sessionStorage.setItem(
          'unauthorized-approval-url',
          `${import.meta.env.VITE_POWERHUB_URL}/user/authorize?token=${
            userProfile.approvalToken
          }`,
        )
        router.push({ path: '/unauthorized' })
      } else if (userProfile.email === '' && userProfile.status === '') {
        const currentEmail = window.sessionStorage.getItem('current-user-email')
        if (currentEmail) {
          window.sessionStorage.setItem(
            'unauthorized-email',
            currentEmail.toString(),
          )
        }
        window.sessionStorage.setItem('unauthorized-status', 'inactive')
        router.push({ path: '/unauthorized' })
      }
    })
    .catch(async err => {
      console.log(err)
    })
}

export async function logout() {
  deleteCache(TOKEN)
  if (!webAuth) {
    initAuth0()
  }
  webAuth.logout({
    returnTo: window.location.origin,
  })
}

async function trackUserActivity() {
  try {
    let page = ''
    if (window.location.href.match('/campaign-list')) {
      page = 'strategy'
    } else if (window.location.href.match('/strategy-assignment/detail/')) {
      page = 'strategy-detail'
    } else if (window.location.href.match('/strategy-assignment')) {
      page = 'strategy'
    } else {
      page = 'strategy'
    }
    await activityTrackUserLogin(page)
  } catch (e) {}
}
