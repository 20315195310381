import { defineStore } from 'pinia'
import _i18n from '@/plugins/i18n'
import type { CommonNS } from '@/typings'
import { Common } from '@/typings'

export const useCommonStore = defineStore(Common.name, {
  state: (): Common.State => ({
    locale: _i18n.global.locale.value as CommonNS.Language,
    expandSidebar: true,
    renderAllowed: false,
    customNTheme: {},
  }),
  actions: {
    switchLang(lang: CommonNS.Language) {
      this.locale = lang
      _i18n.global.locale.value = lang
    },
  },
})
