import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import type { API } from '@/apis/path'

export enum MethodEnum {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export interface AppAxiosInstance extends AxiosInstance {
  (config?: AxiosRequestConfig): Promise<void>
  (url: string, config?: AxiosRequestConfig): Promise<void>
}
export interface RequestConfig extends AxiosRequestConfig {
  api: API
  query?: { [key: string]: string }
  signal?: AbortSignal
  doneHook?: (bool: boolean) => void
}

export interface ResponseBody {
  data: any
  msg: string
  code: string
  ok?: boolean
}

export type HttpFunction = {
  (config: RequestConfig): Promise<ResponseBody['data']>
}

export type HttpObject = {
  [key in MethodEnum]: (config: RequestConfig) => Promise<ResponseBody['data']>
}

export interface Http extends HttpObject, HttpFunction {}

export interface ApiParamProps {
  [key: string]: any
  data?: any
}

export interface ResolveDataProps {
  isLoading: boolean
  response: any
}

export interface RejectDataProps {
  isLoading: boolean
  error: any
}
