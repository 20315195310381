import i18n from '@/plugins/i18n'

const useTranslation = () => {
  const { t, n, te } = i18n.global as any

  const translate = {
    t,
    n,
    te,
    trans(key: string, replace?: any) {
      return te(key) ? t(key, replace) : key
    },
    transGroup(group: string, key: string, replace?: any) {
      return translate.trans(`${group}.${key}`, replace)
    },
    transGeneral(key: string, replace?: any) {
      return translate.transGroup('general', key, replace)
    },
    transButton(key: string, replace?: any) {
      return translate.transGeneral(`buttons.${key}`, replace)
    },
    transValid(key: string, replace?: any) {
      return translate.transGeneral(`validation.${key}`, replace)
    },
    transPlaceholder(key: string, replace?: any) {
      return translate.transGeneral(`placeholder.${key}`, replace)
    },
    transMenu(key: string, replace?: any) {
      return translate.transGroup('menus', key, replace)
    },
    transTable(key: string, replace?: any) {
      return translate.transGeneral(`table.fields.${key}`, replace)
    },
    transTableTooltip(key: string, replace?: any) {
      return translate.transGeneral(`table.tooltips.${key}`, replace)
    },
    transTooltip(key: string, replace?: any) {
      return translate.transGeneral(`tooltips.${key}`, replace)
    },
    transMessage(key: string, replace?: any) {
      return translate.transGeneral(`messages.${key}`, replace)
    },
    transPopup(key: string, replace?: any) {
      return translate.transGeneral(`popup.${key}`, replace)
    },
  }

  return translate
}
export default useTranslation
