import axios from 'axios'
import { TOKEN } from '@/configs/common'
import { getCache } from '@/utils/cache'
import { API, http } from '.'

// export const getToken = (ticket: string) =>
//   axios
//     .post(import.meta.env.VITE_FLYWHEEL_PORTAL_GET_TOKEN, {
//       ticket,
//     })
//     .then((response: any) => ({
//       // token: response?.data?.data?.idToken || '',
//       accountInfo: response?.data?.data?.accountInfo || null,
//     }))

export const logout = () => {
  if (!import.meta.env.DEV) {
    const token = getCache(TOKEN)
    return axios
      .create({
        headers: {
          Authorization: token,
        },
      })
      .post(import.meta.env.VITE_FLYWHEEL_PORTAL_LOGOUT)
  }
  return Promise.resolve()
}

export const checkToken = (token: string) =>
  http.get({ api: API.CHECK_TOKEN, params: { idToken: token } })

export const checkUserProfile = (token: string) =>
  http.get({
    api: API.CHECK_PROFILE,
    headers: {
      Authorization: token,
    },
  })
