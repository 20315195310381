import { defineStore } from 'pinia'
import { DateNS, TradeDesk, TradeDeskNS } from '@/typings'
import { format2T8 } from '@/utils/day'

export const useTradeDeskStore = defineStore(TradeDesk.name, {
  state: (): TradeDesk.State => ({
    topFilters: {
      subPage: TradeDeskNS.SUB_PAGE.OVERVIEW,
      onlyShowAlert: false,
      storeOrderRefreshed: false,
      date: {
        mode: DateNS.DYNAMIC_DATE.today,
        customerTimestamp: null,
      },
      compareDate: {
        mode: DateNS.DYNAMIC_DATE.mom,
        customerTimestamp: null,
        isCompareDate: true,
      },
    },
    config: {
      authorizedStores: [],
    },
  }),
  getters: {
    requestDate(state) {
      return state.topFilters.date.customerTimestamp?.length
        ? `[${state.topFilters.date.customerTimestamp
            .map(stamp => format2T8(stamp))
            .join(',')}]`
        : ''
    },
    requestCompareDate(state) {
      return state.topFilters.compareDate.customerTimestamp?.length &&
        state.topFilters.compareDate.isCompareDate
        ? `[${state.topFilters.compareDate.customerTimestamp
            .map(stamp => format2T8(stamp))
            .join(',')}]`
        : ''
    },
  },
})
