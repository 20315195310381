import type { GlobalThemeOverrides } from 'naive-ui'

const themeOverrides: GlobalThemeOverrides = {
  common: {
    primaryColor: '#1C56FC',
    primaryColorHover: '#1B2E8A',
    textColor1: 'var(--color-neutral-800)',
    textColor2: 'var(--color-neutral-800)',
  },
  DatePicker: {
    itemBorderRadius: '100px',
    panelBorderRadius: '0px',
  },
  Input: {
    heightMedium: 'var(--el-component-size)',
    fontSizeMedium: 'var(--fontSize-base)',
    lineHeight: '1.25rem',
    textColor: 'var(--color-neutral-800)',
    // caretColor: '#000000',
    // borderHover: '1px solid #409effFF',
    // borderFocus: '1px solid #409effFF',
    // boxShadowFocus: '0',
  },
  Pagination: {
    buttonBorder: 'none',
    buttonBorderHover: 'none',
    buttonBorderPressed: 'none',
    itemBorderDisabled: 'none',
    jumperTextColor: '#8894AA',
    itemColorActive: '#F5F6FA',
    itemBorderActive: 'none',
  },
  DataTable: {
    paginationMargin: '0',
    tdColorHover: '#fff',
    thColorHover: '#fafafc',
    peers: {
      Button: {
        fontSizeTiny: '13px',
        textColor: 'var(--color-neutral-700)',
        paddingTiny: '4px 12px',
        heightTiny: 'auto',
        borderRadiusTiny: '4px',
      },
    },
  },
  Tabs: {
    tabBorderColor: '#E1E5EA',
  },
  // Cascader: {
  //   menuDividerColor: '#c0c4ccFF',
  // },
  // Checkbox: {
  //   colorChecked: '#409effFF',
  // },
}
export default themeOverrides
